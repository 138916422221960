import React from "react";

function App(props){

  return (
    <div>
        <img src="https://oaklandcreative.co.uk/wp-content/themes/oaklandcreative/img/logo/logo-oakland-creative-light.png" alt="oakland Creative" />
    </div>
  )

}

export default App;